/* eslint-disable max-len */
import _ from 'lodash'
import { Link } from 'gatsby'
import React from 'react'
import Slider from 'react-slick'
import { getUrlMedia, getRoute } from '../../../../helpers'
import { withTrans } from '../../../../i18n/withTrans'
import CardMatter from '../card-matter'
import LeftArrow from '../../../../assets/icons/pacificcross_icons_arrow-left.svg'
import RightArrow from '../../../../assets/icons/pacificcross_icons_arrow-right.svg'
import './head-experiences-styles.scss'

const HeadExperiences = ({ data, i18n }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          dots: false,
          swipeToSlide: true,
          arrows: false,
        },
      },
    ],
  }

  return (
    <div className="experienceHead__container">
      <div className="experienceHead__navigation">
        <Link to={getRoute({
          lang: i18n.language,
          route: `/experience/${_.get(data, 'previous.slug')}`,
        })}
        className="experienceHead__boxArrows"
        >
          <div className="experienceHead__arrow">
            <LeftArrow width="20" />
          </div>
          <p className="before">{_.get(data, 'previous.name')}</p>
        </Link>
        <div className="experienceHead__navigation--active">
          <p>{_.get(data, 'name')}</p>
        </div>
        <Link to={getRoute({
          lang: i18n.language,
          route: `/experience/${_.get(data, 'next.slug')}`,
        })}
        className="experienceHead__boxArrows"
        >
          <p className="before">{_.get(data, 'next.name')}</p>
          <div className="experienceHead__arrow">
            <RightArrow width="20" />
          </div>
        </Link>
      </div>
      <Slider {...settings}>
        {_.map(_.get(data, 'slider'), (slide, idx) => (
          <div key={idx}>
            <CardMatter
              image={getUrlMedia(_.get(slide, 'image'))}
              title={_.get(slide, 'title')}
              content={_.get(slide, 'description')}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default withTrans(HeadExperiences)
