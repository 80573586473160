import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Cta, Spinner, Seo } from '../../components'
import { BlogCarousel, HeadExperiences, RelatedPlans } from './components'
import './experience-style.scss'

const ExperienceView = ({ loading, data }) => {
  const { t, i18n } = useTranslation()
  if (loading) {
    return <Spinner loading={loading} />
  }
  return (
    <div className="experience__container container-fluid px-0">
      <Seo title={`${t('seo.title')}: Experiences`} />
      <HeadExperiences t={t} data={data} />
      <BlogCarousel t={t} i18n={i18n} blogs={_.get(data, 'featured_blogs', data.featured_blog)} />
      <div className="container-xxl experience__content">
        <h2 className="experience__title">{_.get(data, 'statement')}</h2>
      </div>
      <RelatedPlans t={t} products={_.get(data, 'related_products')} />
      <div className="container-xxl px-0">
        <Cta
          withAppend={true}
          info={{
            buttonText: t('product.header.buttonText'),
          }}
        />
      </div>
    </div>
  )
}

export default ExperienceView
