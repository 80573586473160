import React from 'react'
import { CarouselCards, CardWithPicture } from '../../../../components'
import './blog-carousel-style.scss'

const BlogCarousel = ({ blogs }) => (
  <div className="container-xxl experienceBlog__container">
    <CarouselCards
      data={blogs}
      arrows={true}
      tag="Featured blogs"
      parentLink="blog"
      inner={CardWithPicture}
      extraProps={{
        pictureDisplace: true,
      }}
    />
  </div>
)

export default BlogCarousel
